import { Html, useProgress } from "@react-three/drei"
import './style/PercentageLoader.css'

const PercentageLoader = () => {
  const { progress } = useProgress()

  return (
    <Html>
      <span className="canvas-loader"> </span>
      <p
        style={{ 
          fontSize: 20,
          color: 'grey',
           fontWeight : 600
         }}   
      
      >{progress.toFixed(2)}%</p>
    </Html>
  )
}
export default PercentageLoader