import React from 'react'
import './style/ControlPannel.css'
import { useState } from 'react';

import { Expand, SlidersHorizontal, Camera, Plus, Minus, Image, Play, Pause } from 'lucide-react';
import { useEffect } from 'react';
import { getStyleNumbers } from '../../services/handleProducts';
import { captureAndDownload } from '../../services/captureHandler';
import { useRef } from 'react';


const metalColorsMap = {
  '#e8bc6b': '/gold.jpeg',
  '#c8c8c8': '/silver.jpeg',
  '#ebc4af': '/rose-gold.jpeg'
}

const metalColors = [
  '#e8bc6b',,
  '#c8c8c8',
  '#ebc4af'
]
const diamondColorsMap = {
  '#ffffff': '/white-diamond.jpeg',
  '#FFFFC5': '/yellow-diamond.jpeg',
  '#FFCCCB': 'Ruby-diamond.jpeg',
}

const diamondColors = [
  '#ffffff',
  '#FFFFC5',
  '#FFCCCB'
]

export default function ControlPannel(props) {
  const [activeMetal, setActiveMetal] = useState(false);
  const [activeDiamond, setActiveDiamond] = useState(false);
  const [activeModel, setActiveModel] = useState(false);
  const [toggleMore, setToggleMore] = useState(false);
  const [modelVariations, setModelVariations] = useState(null);
  const [captureToggle, setCaptureToggle] = useState(false);
  const mediaRecorderRef = useRef();
  const chunksRef = useRef([]);
  
  const handleActiveState = (state) => {
    if(state === 1) {
      setActiveMetal(prev => !prev);
      setActiveDiamond(false);
      setActiveModel(false);
    }
    if(state === 2) {
      setActiveMetal(false);
      setActiveDiamond(prev => !prev);
      setActiveModel(false);
    }
    else if(state === 3) {
      setActiveMetal(false);
      setActiveDiamond(false);
      setActiveModel(prev => !prev);
    }
  }

  const startRecording = () => {
    props.setIsRotate(true);
    const canvas = props.canvasRef.current;
    chunksRef.current = [];
    // alert("Recording Started!")
    if (canvas.captureStream) {
      const stream = canvas.captureStream(60);
      const options = {
        videoBitsPerSecond: 25000000,  // 5Mbps, adjust as needed
      };
      mediaRecorderRef.current = new MediaRecorder(stream, options);
      
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = `${props.currStyleNumber}.webm`;
        a.click();
        URL.revokeObjectURL(url);
      };
      mediaRecorderRef.current.start();
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    props.setIsRotate(false);
  };
  

  const handleDownload = () => {
    console.log('inside handleDownload');
    
    captureAndDownload(props.meshGl, props.meshCamera, props.meshScene, props.currStyleNumber);
  }

  useEffect(()=> {
    const productNames = props.styleNumbers && props.styleNumbers.map(product => product.productName);
    setModelVariations(productNames);
    
  },[props.styleNumbers])
  


  return (
    <>
      <div className='MainControls'>
        <div className='MainControls-container'>
            <div className='options'>
                <div className={activeMetal ? 'option active tooltip' : 'option tooltip'} onClick={() => handleActiveState(1)}>
                   Metal
                   {!activeMetal && <span class="tooltiptext">Change Metal</span>}
                </div>
                <div className={activeDiamond ? 'option active tooltip' : 'option tooltip'} onClick={() => handleActiveState(2)}>
                   Diamond
                   {!activeDiamond && <span class="tooltiptext">Change Diamond</span>}
                </div>
                {!props.styleNo && <div className={activeModel ? 'option active tooltip' : 'option tooltip'} onClick={() => handleActiveState(3)}>
                   Ring
                   {!activeModel && <span class="tooltiptext">Change Ring</span>}
                </div>}
            </div>

            <div className={toggleMore ? 'more open tooltip disabled-btn' : 'more tooltip disabled-btn'}>
                {!toggleMore ? <span>More</span> : <span>X</span>}
                <span class="tooltiptext">Coming Soon!</span>
            </div>
        </div>
      </div>

      <div className={activeMetal ? 'variations' : 'variations hidden'}>
          {metalColors.map((item, idx) => (
            <div key={idx} className='variation' >
                <div className='img-container'  >
                    <img className={item === props.modelHex && 'select'} src={metalColorsMap[item]} onClick={() => props.setModelHex(item)}/>
                </div>
            </div>
          ))}
      </div>

      <div className={activeDiamond ? 'variations' : 'variations hidden'}>
          {diamondColors.map((item, idx) => (
            <div key={idx} className='variation'>
                <div className='img-container'>
                    <img className={item === props.diamondHex && 'select'} src={diamondColorsMap[item]} onClick={() => props.setDiamondHex(item)}/>
                </div>
            </div>
          ))}
      </div>

      {modelVariations && !props.styleNo && <div className={activeModel ? 'variations models' : 'variations models hidden'}>
          {modelVariations.map((item, idx) => (
            <div key={idx} className={item === props.currStyleNumber ? 'variation model selected' : 'variation model'}>
                <div className='' onClick={() => props.handleStyleNoChange(item)}>
                    {item}
                </div>
            </div>
          ))}
      </div>}

      <div className='CaptureControls'>
          <div className='tooltip disabled'><Expand size={20} className='icons'/>
              <span class="tooltiptext">Full Screen</span>
          </div>
          <div className='tooltip disabled'><SlidersHorizontal size={20}  className='icons'/>
              <span class="tooltiptext">Quality</span>
          </div>
          <div className='tooltip'><Camera size={20} className={captureToggle ? 'icons active-icons' : 'icons'} onClick={() => setCaptureToggle(prev => !prev)}/>
              <span class="tooltiptext">Capture</span>
          </div>
      </div>

      <div className={captureToggle ? 'record' : 'record hidden2'}>
          <div className='tooltip'>
              <Image className='icons' size={20} onClick={handleDownload}/>
              <span class="tooltiptext">Download Image</span>
          </div>
          <div className='tooltip'>
              <Play className='icons' size={20} onClick={startRecording}/>
              <span class="tooltiptext">Play</span>
          </div>
          <div className='tooltip'>
              <Pause className='icons' size={20} onClick={stopRecording}/>
              <span class="tooltiptext">Pause/Download video</span>
          </div>
      </div>

      <div className={props.isRotate ? 'rec-indicator' : 'hidden3'}/>

      <div className='ZoomControls'>
          <Plus size={20} className='icons' onClick={() => props.setZoomLevel(prev => prev + 0.2)}/>
          <Minus size={20} className='icons' onClick={() => props.setZoomLevel(prev => prev - 0.2)}/>
      </div>
    </>
  )
}
