export const captureAndDownload = (meshGl, meshCamera, meshScene, styleNo) => {
    const gl = meshGl;
    const scene = meshScene;
    const camera = meshCamera;
    if (gl && scene && camera) {
        gl.render(scene, camera)
        const dataUrl = gl.domElement.toDataURL('image/png')
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = `${styleNo}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    }
}