import React, { Suspense, useEffect, useState } from 'react'
import ControlPannel from '../ControlPannel'
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber';
import { BASE_URL } from '../../lib/common';
import './style/PlaygroundScreen.css'
import { AccumulativeShadows, Center, Environment, OrbitControls, RandomizedLight, useGLTF } from '@react-three/drei';
import { GLTFLoader, RGBELoader } from 'three-stdlib'
import { fetchHDRFile, HDREnvironment } from '../../services/handleHDR';
import PercentageLoader from '../PercentageLoader';
import { getProducts } from '../../services/handleProducts';
import Diamond from '../Diamond';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Vector3 } from 'three';
import Loader from "react-js-loader";

const hdr_env_diamond = '/diahdr4.hdr';

let diamonds = [];

const hdrFiles = ['env-metal-1.hdr'];

function Loading() {
  return <div className='loading'>
       <Loader type="bubble-loop" bgColor='black' color='white' title={""} size={80} />
   </div>
}


export default function PlaygroundScreen() {
  const [styleNumbers, setStyleNumbers] = useState(null);
  const [currStyleNumber, setCurrStyleNumber] = useState();
  const [currEnvHDR, setCurrEnvHDR] = useState(hdrFiles[0]);
  const [isRotate, setIsRotate] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [modelHex, setModelHex] = useState('#c8c8c8');
  const [diamondHex, setDiamondHex] = useState('#ffffff');
  const [isRotate2, setIsRotate2] = useState(false);
  const [isRotate3, setIsRotate3] = useState(false);
  const meshGl = useRef();
  const meshScene = useRef();
  const meshCamera = useRef();
  const canvasRef = useRef();

  const styleNo = useParams();
  

  console.log(modelHex);
  console.log(diamondHex);
  
  const handleStyleNoChange = (value) => {
    setCurrStyleNumber(value);
  }  

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const result = await getProducts(); // await the async function here
        setStyleNumbers(result);
        console.log(result);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchDataAsync();
  }, [])

  useEffect(() => {
    styleNumbers && setCurrStyleNumber(styleNumbers[2]['productName'])
  }, [styleNumbers])

  const url = currEnvHDR && BASE_URL + `hdr-files/${currEnvHDR}`;

   function Jewel(props) {
    const vec = new Vector3();
    const points = [
      new Vector3(0, 0, 4), // First point
      new Vector3(3, 1, 2), // Second point
      new Vector3(0, 2, 6), // Third point
    ];

    const points2 = [
      new Vector3(0, 8, 4), // First point
      new Vector3(3, 1, 2), // Second point
      new Vector3(0, 0, 8), // Third point
    ];
    const { gl, scene: threeScene, camera } = useThree(); 
    const [currentPoint, setCurrentPoint] = useState(0);
    meshGl.current = gl;
    meshScene.current = threeScene;
    meshCamera.current = camera;
    const styleNo = props.styleNo;
    const groupRef = useRef(null);
    const modelUrl = styleNo && BASE_URL + `getFile?style_no=${styleNo}`;
    const texture = useLoader(RGBELoader, `${process.env.PUBLIC_URL || ''}${hdr_env_diamond}`)

    function Animation(state, points) {

        state.camera.lookAt(groupRef.current.position);
        state.camera.position.lerp(points[currentPoint], 0.01);
        state.camera.updateProjectionMatrix();
    
        if (state.camera.position.distanceTo(points[currentPoint]) < 1.5) {
          currentPoint < points.length-1 && setCurrentPoint((prevPoint) => (prevPoint + 1));
        }
    }

    useEffect(() => {
        return () => {
            diamonds.forEach(geometry => geometry.dispose());
        };
    }, [diamonds]);

    diamonds = [];
    const { scene, nodes: node } = useGLTF(modelUrl, true);
    useEffect(() => {
        camera.zoom = zoomLevel;           // Set the zoom level
        camera.updateProjectionMatrix(); // Must call this to apply the zoom change
    }, [zoomLevel, camera]);

    useFrame((state) => { 
      if(isRotate2) Animation(state, points);
      else if(isRotate3) Animation(state, points2);
      
      return null
    })


    scene.traverse((child) => {
        if (child.isMesh && (!child.name.startsWith("Diamond"))) {
            child.material.metalness = 1;
            child.material.roughness = 0.08;
            child.material.color.set(props.modelHex);
            child.castShadow = true;
        }
        if (
            child.isMesh
            && (child.name.startsWith("Diamond"))) {
            diamonds.push(child.geometry.clone());
            child.castShadow = true;
            child.material.visible = false;
        }
        if(child.isMesh && styleNo === 'JSHBR-06' && (child.name.startsWith('Object_6') || child.name.startsWith('Prong'))) {
          child.material.color.set('#c8c8c8');
      }
    })

    return <group ref={groupRef} castShadow>
        <primitive object={scene} scale={props.scale}  castShadow/>
        {diamonds.map((item, index) => (
            <Diamond {...props} key={index}
                scale={0.1}
                texture={texture}
                color={diamondHex}
                geometry={item} />
        ))}
    </group>
}
  
  return (
    
    <div className='PlaygroundScreen'>
    <Suspense fallback={<Loading />}>
      <Canvas className='canvas'
                    dpr={[1, 2]}
                    ref={canvasRef}
                    performance={{min: 0.1}} 
                    frameloop='demand' shadows gl={{ antialias: true }} 
                    camera={{ position: [0, 5, 8], far: 200, fov: 25, rotation: [3, 0 ,0] }}
      >

        
            <group position={[0, -0.25, 0]}>
                <Center top position={[0, 0, 0]} rotation={[0, 0, 0]}>
                    {currStyleNumber && <Jewel styleNo={!styleNo['styleNo'] ? currStyleNumber : styleNo['styleNo']} scale={100} url={url} zoomLevel={zoomLevel} 
                      modelHex={modelHex} 
                      diamondHex={diamondHex} 
                      />}
                </Center>
                <AccumulativeShadows temporal frames={100} color={'black'} opacity={0.7}>
                    <RandomizedLight radius={5} position={[10, 5, -2]} />
                </AccumulativeShadows>
            </group>
            <HDREnvironment url={url}/>
            <OrbitControls enableRotate={true} enablePan={false} rotation={true} autoRotate={isRotate} />
      </Canvas>
      </Suspense>
      <ControlPannel 
          zoomLevel={zoomLevel}
          setZoomLevel={setZoomLevel}
          currStyleNumber={currStyleNumber}
          styleNumbers={styleNumbers}
          modelHex={modelHex}
          setModelHex={setModelHex}
          diamondHex={diamondHex}
          setDiamondHex={setDiamondHex}
          handleStyleNoChange={handleStyleNoChange}
          styleNo={styleNo['styleNo']}
          meshGl={meshGl.current}
          meshScene={meshScene.current}
          meshCamera={meshCamera.current}
          canvasRef={canvasRef}
          isRotate={isRotate}
          setIsRotate={setIsRotate}
          isRotate2={isRotate2}
          setIsRotate2={setIsRotate2}
          isRotate3={isRotate3}
          setIsRotate3={setIsRotate3}
      />
    </div>
  )
}
