// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlaygroundScreen{
    display: flex;
    width: full;
    height: 100vh;
    align-items: center;
    justify-content: center;
    border-radius: 40px;

    /* background-color: rgb(83, 0, 0); */
    /* overflow: hidden; */
}
.canvas{
    display: flex;
    height:  97% !important;
    width: 500px;
    border: 10px solid white;
    border-radius: 40px;
    /* padding: 10px; */
    /* height: 90vh; */
    /* margin: 20px 0px 20px 0px; */
    background-color: rgb(213, 212, 212);
}
`, "",{"version":3,"sources":["webpack://./src/components/PlaygroundScreen/style/PlaygroundScreen.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;;IAEnB,qCAAqC;IACrC,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,wBAAwB;IACxB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,+BAA+B;IAC/B,oCAAoC;AACxC","sourcesContent":[".PlaygroundScreen{\n    display: flex;\n    width: full;\n    height: 100vh;\n    align-items: center;\n    justify-content: center;\n    border-radius: 40px;\n\n    /* background-color: rgb(83, 0, 0); */\n    /* overflow: hidden; */\n}\n.canvas{\n    display: flex;\n    height:  97% !important;\n    width: 500px;\n    border: 10px solid white;\n    border-radius: 40px;\n    /* padding: 10px; */\n    /* height: 90vh; */\n    /* margin: 20px 0px 20px 0px; */\n    background-color: rgb(213, 212, 212);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
