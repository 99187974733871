import { BASE_URL } from "../lib/common";

const currCollection = 'Playground';

export const getProducts = async () => {
    try {
        const response = await fetch(`${BASE_URL}getProducts?&&collection_name=${currCollection}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const { totalPages, hasNext, hasPrev, products } = await response.json();
        // let collectionOptions = [];
        // for (let collection of data) {
        //     collectionOptions.push(collection.collectionName);s
        // }
        return products;
    } catch (error) {
        console.error('Error during fetch:', error);
        throw error;
    }
}


export const getStyleNumbers = (products) => {
    const styleNumbers = [];
    for(let i = 0; i < products.length; i++) {
        styleNumbers.push(products[i]['productName']);
    }
}