import { BASE_URL } from "../lib/common";
import { GLTFLoader, RGBELoader } from 'three-stdlib';
import * as THREE from 'three';
import { useLoader, useThree } from '@react-three/fiber';

export const fetchHDRFile = async (filename) => {
    console.log('inside fetchHDRFile()--------------', filename);
    try {
        const url = BASE_URL + `hdr-files/${filename}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.arrayBuffer();
        // console.log('hdr-file-data: ', data);
        const file = new Blob([data], { type: "image/vnd.radiance" });
        return URL.createObjectURL(file);
    } catch (error) {
        return null;
    }
};

export const HDREnvironment = ({url}) => {
    const { scene } = useThree();

    const hdrTexture = useLoader(RGBELoader, url);
    // const hdrTexture = useLoader(RGBELoader, `${process.env.PUBLIC_URL || ''}/unity_env.hdr`);
    const pmremGenerator = new THREE.PMREMGenerator(useThree().gl);
    const envMap = pmremGenerator.fromEquirectangular(hdrTexture).texture;
    scene.environment = envMap;
    hdrTexture.dispose();
    pmremGenerator.dispose();
    return null;
  };